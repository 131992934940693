<template>
  <div class="animated fadeIn">

   <!--  <b-btn variant="primary" @click=getCmd() size="sm">Commands</b-btn> -->
    <template v-if="isAdmin(role_id)">
      <b-btn variant="primary" @click=newMachine() size="lg" >{{ $t('message.newMachine') }}</b-btn><br></br>
    </template>
    <b-card :title="$t('message.allMachine')">
      <b-form @submit.prevent="searchFn">
        <div class="row">
          <div class="col-sm-6 col-md-3">
            {{ $t('message.type') }} : <br>
            <b-form-select v-model="searchData.typeId" :options="$t('message.arrays.machineTypeId')"/>
          </div>
          <div class="col-sm-6 col-md-6">
            {{ $t("message.machineId") }} : <br>
            <multi-list-select 
              :list="machineData"
              option-value="machineId"
              option-text="name"
              :selected-items="selectedMachine"
              placeholder="All"
              @select="onSelectMachine">
            </multi-list-select>
          </div>
<!--
          <div class="col-sm-6 col-md-2">
            ซีเรียลตู้ : <br>
            <b-form-input v-model="searchData.serial"/>
          </div>
-->
          <div class="col-sm-6 col-md-3">
            <br><b-btn variant="success" type="submit"><i class="fa fa-search"></i>&nbsp;{{ $t('message.search') }}</b-btn>
          </div>

          <div class="col-sm-6 col-md-3">
            {{ $t('message.machineSerial') }} : <br>
            <b-form-input v-model="searchData.serial" />
          </div>
          <div class="col-sm-6 col-md-3">
            {{ $t('message.version') }} : <br>
            <b-form-input v-model="searchData.version" />
          </div>
          <div class="col-sm-6 col-md-3">
            {{ $t('message.onlineOfline') }} : <br>
            <b-form-select v-model="searchData.online" :options="$t('message.arrays.onlineOfline')"/>
          </div>
        </div>
      </b-form><br>
    <div class="table-responsive">
    <table class="table2">
      <thead class="bg-primary" style="text-align: center">
        <tr>
          <th>{{ $t('message.machineId') }}</th>
          <th>{{ $t('message.type') }}</th>
          <th>{{ $t('message.machineSerial') }}</th>
          <th>{{ $t('message.machineModel') }}</th>
          <th>{{ $t('message.machineName') }}</th>
          <th>{{ $t('message.moneyInbox') }}</th>
          <th>{{ $t('message.status') }}</th>
          <th>{{ $t('message.version') }}</th>
          <th>{{ $t('message.lastOnline') }}</th>
          <th></th>
          <th></th>
         </tr>
       </thead>
       <tbody>
           <template v-for="item in machines">
             <tr>
               <td style="text-align: center">{{item.id}}</td>
               <td style="text-align: center">{{item.model.type.name}}</td>
               <td>{{item.serial}}</td>
               <td>{{item.model.name}}</td>
               <td>{{item.name}}</td>
               <td style="text-align: right"><template v-if="item.inbox"><span class="spanCustom" @click="showInboxModal(item)">{{item.inbox.total}}</span></template></td>
               <template v-if="now - new Date(item.lastOnline) <= 1800000"><td style="text-align: center"><span class="badge badge-success" style="font-size: 100%">{{ $t('message.online') }}</span></td></template>
               <template v-else><td style="text-align: center"><span class="badge badge-danger" style="font-size: 100%">{{ $t('message.offline') }}</span></td></template>
               <td style="text-align: right">{{item.version}}</td>
               <td style="text-align: center">{{item.lastOnline | moment("YYYY-MM-DD HH:mm")}}</td>
               <td><b-btn variant="warning" @click=details(item) size="sm">{{ $t('message.deviceDetails') }}</b-btn></td>
               <td>
                 <b-btn variant="primary" @click=settings(item) size="sm">{{ $t('message.setting') }}</b-btn>
               </td>
            </tr>
          </template>
       </tbody>

    </table>
    </div><br>

    <b-pagination size="md" :total-rows=totalRows v-model="pagination" :per-page=20 @input="pageChanged(pagination)"></b-pagination>

<!--    <uib-pagination :total-items="totalRows" :items-per-page="20" v-model="pagination" class="pagination-sm" @change="pageChanged(pagination.currentPage)"></uib-pagination>-->


    </b-card>
    <b-modal ref="detailsModal" hide-footer hide-header size="lg">
      <table class="table-modal" align="center">
        <template v-if="modalData.type === 'washing'">
            <thead style="text-align-last: center">
                <th>{{ $t('message.deviceId') }}</th>
                <th>{{ $t('message.enable') }}</th>
                <th>{{ $t('message.status') }}</th>
                <th>{{ $t('message.machineType') }}</th>
            </thead>
            <template v-for="items in modalData">
                <tr align="center">
                    <td>{{items.id}}</td>
                    <template v-if="(items.enable) === true"><td width=30%><span class="badge badge-success" style="font-size: 100%">{{ $t('message.enable') }}</span></td></template>
                    <template v-else-if="(items.enable) === false"><td><span class="badge badge-danger" style="font-size: 100%">{{ $t('message.disable') }}</span></td></template>
                    <template v-if="(items.status) === 'WASHING'"><td><span class="badge badge-warning" style="font-size: 100%">{{ $t('message.washing') }}</span></td></template>
                    <template v-else-if="(items.status) === 'READY'"><td><span class="badge badge-success" style="font-size: 100%">{{ $t('message.ready') }}</span></td></template>
                    <template v-else-if="(items.status) === 'ERROR'"><td><span class="badge badge-danger" style="font-size: 100%">{{ $t('message.machineError') }}</span></td></template>
                    <td>{{items.deviceDetails.name}}</td>
                </tr>
            </template>  
        </template>
        <template v-else>        
            <table class="table-modal">
                <thead style="text-align-last: center">
                    <th>{{ $t('message.slot') }}</th>  
                    <th>{{ $t('message.productName') }}</th>  
                    <th>{{ $t('message.amount') }}</th>  
                    <th>{{ $t('message.price') }}</th> 
                </thead>
                <template v-for="items in modalData">
                    <tr style="text-align: center">
                        <td>{{items.slotId}}</td> 
                        <td>{{items.product.name}}</td>
                        <template v-if="items.enable">
                            <template v-if="items.qty <= 1"><span style="color: red">{{ $t('message.outOfStock') }}</span></template>
                            <template v-else><td>{{items.qty}}</td></template>
                        </template>
                        <template v-else><span style="color: red">{{ $t('message.disable') }}</span></template>
                        <td>{{items.price}}</td>
                    </tr>
                </template>
            </table>       
        </template> 
      </table>
    </b-modal>


    <b-modal ref="inboxModal" hide-footer hide-header>
    <b-tabs>
    <b-tab title="เงินในตู้ทั้งหมด">
      <table class="table-modal" style="text-align: center">
        <thead style="text-align-last: center">
          <th>ประเภท</th>
          <th>จำนวน</th>
          <th>เป็นเงิน</th>
        </thead>
        <tbody>
          <tr><th>เหรียญ</th><td></td><td></td></tr>
          <template v-for="(value, key) in inboxData.coin">
            <tr>
              <td>{{key}} บาท</td>
              <td>{{value}}</td>
              <td>{{value*key}} บาท</td>
            </tr>
          </template>
          <tr><th>แบงค์</th><td></td><td></td></tr>
          <template v-for="(value, key) in inboxData.bill">
            <tr>
              <td>{{key}} บาท</td>
              <td>{{value}}</td>
              <td>{{value*key}} บาท</td>
            </tr>
          </template>
        </tbody>
        <tfoot style="text-align-last: center">
          <td></td>
          <th>รวมเป็นเงิน</th>
          <th>{{inboxData.total}} บาท</th>
        </tfoot>
      </table>



<!--
      <table class="table no-border table-sm" style="text-align: center">
        <thead style="text-align-last: center">
          <th>ประเภท</th>
          <th>จำนวน</th>
          <th>เป็นเงิน</th>
        </thead>
        <tbody>
          <tr><th>เหรียญ</th><td></td><td></td></tr>
          <template v-for="(value, key) in changeData.coin">
            <tr>
              <td>{{key}} บาท</td>
              <td>{{value}}</td>
              <td>{{value*key}} บาท</td>
            </tr>
          </template>
          <tr><th>แบงค์</th><td></td><td></td></tr>
          <template v-for="(value, key) in changeData.bill">
            <tr>
              <td>{{key}} บาท</td>
              <td>{{value}}</td>
              <td>{{value*key}} บาท</td>
            </tr>
          </template>
        </tbody>
        <tfoot style="text-align-last: center">
          <td></td>
          <th>รวมเป็นเงิน</th>
          <th>{{inboxData.total}} บาท</th>
        </tfoot>
      </table>
-->

      </b-tab>
      <b-tab title="เงินทอน">
        <table class="table-modal" style="text-align: center">
          <thead style="text-align-last: center">
            <th>ประเภท</th>
            <th>จำนวน</th>
            <th>เป็นเงิน</th>
          </thead>
          <tbody>
            <tr><td>เหรียญ</td><td></td><td></td></tr>
            <template v-for="(value, key) in changeData.coin">
              <tr>
                <td>{{key}} บาท</td>
                <td>{{value}}</td>
                <td>{{value*key}} บาท</td>
              </tr>
            </template>
            <tr><th>แบงค์</th><td></td><td></td></tr>
            <template v-for="(value, key) in changeData.bill">
              <tr>
                <td>{{key}} บาท</td>
                <td>{{value}}</td>
                <td>{{value*key}} บาท</td>
              </tr>
            </template>
          </tbody>
          <tfoot style="text-align-last: center">
            <td></td>
            <th>รวมเป็นเงิน</th>
            <th>{{changeData.total}} บาท</th>
          </tfoot>
        </table>
        </b-tab>
      </b-tabs>
    </b-modal>

  </div>
</template>

<script>
import webServices from "../../script";
import DatePicker from "../../custom_modules/vue2-datepicker";
import {
  ModelListSelect,
  MultiListSelect,
} from "../../custom_modules/search-select";
export default {
  name: "orders",
  components: {
    DatePicker,
    ModelListSelect,
    MultiListSelect,
  },
  data() {
    return {
      now: new Date(),
      role_id: window.localStorage.getItem("roleID"),
      rowData: [],
      totalRows: 1,
      pagination: 1,
      searchData: {
        page: 1,
        rows: 20,
        userId: window.localStorage.getItem("userId"),
        modelId: "",
        typeId: "",
        mode: "",
        machineId: "",
      },
      selectedMachine: [],
      machineData: [],
      machines: [],
      modalData: {},
      inboxData: "",
      changeData: {
        coin: "",
        bill: "",
        total: 0,
      },
      type: [
        { value: "", text: "ทั้งหมด" },
        { value: 3, text: "ฟาร์มซักผ้า" },
        { value: 1, text: "ตู้น้ำ" },
        { value: 2, text: "ตู้น้ำมัน" },
      ],
    };
  },
  mounted() {
    this.getMachine();
    this.getMachineList();
    // this.getDeviceType()
  },
  methods: {
    isAdmin(role) {
      return webServices.isAdmin(role);
    },
    newMachine() {
      this.$router.push("/machine/new_machine");
    },
    searchFn() {
      this.getMachine(this.searchData);
    },
    settings(data) {
      this.$router.push({
        path: `/machine/setting/${data.id}`,
        params: { id: data.id },
      });
    },
    onSelectMachine(items, lastSelectItem) {
      // console.log(items)
      this.selectedMachine = items;
      this.searchData.machineId = this.selectedMachine.map(
        (data) => data.machineId
      );
    },
    showInboxModal(data) {
      function slice(object, keys) {
        return Object.keys(object)
          .filter(function (key) {
            return keys.indexOf(key) >= 0;
          })
          .reduce(function (acc, key) {
            acc[key] = object[key];
            return acc;
          }, {});
      }
      let coin = slice(data.inbox, ["1", "2", "5", "10"]);
      let bill = slice(data.inbox, ["20", "50", "100", "500", "1000"]);
      this.inboxData = {
        coin: coin,
        bill: bill,
        total: data.inbox.total,
      };
      // console.log(data.change)
      if (data.change) {
        let total = 0;
        // this.changeData.coin = data.change.coin
        // this.changeData.bill = data.change.bill
        const inbox = Object.assign(data.change.coin, data.change.bill);
        Object.keys(inbox).forEach((key) => {
          total += key * inbox[key];
        });
        let coinC = slice(data.change.coin, ["1", "2", "5", "10"]);
        let billC = slice(data.change.bill, ["20", "50", "100", "500", "1000"]);
        this.changeData = {
          coin: coinC,
          bill: billC,
          total: total,
        };
      }
      this.$refs.inboxModal.show();
    },
    details(data) {
      this.$Progress.start();
      if (data.model.type.name === "washing") {
        webServices.getDevice(data.id).then((res) => {
          this.$Progress.finish();
          // console.log(res.data)
          this.modalData = res.data;
          this.modalData.type = "washing";
          this.$refs.detailsModal.show();
        });
      } else {
        webServices.getSlots(data.id).then((res) => {
          this.$Progress.finish();
          // console.log(res.data)
          this.modalData = res.data;
          this.modalData.type = "other";
          this.$refs.detailsModal.show();
        });
      }
    },
    getMachine() {
      this.$Progress.start();
      webServices
        .getMachine(this.searchData)
        .then((res) => {
          this.$Progress.finish();
          this.totalRows = res.data.count;
          this.machines = res.data.rows;
        })
        .catch((err) => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          console.log("error @MACHINE");
          console.log(err);
          const errorText = {
            error: err.response.data,
            code: err.response.status,
            text: err.response.statusText,
          };
          this.$toast.error({
            title: "ERROR",
            message: JSON.stringify(errorText),
          });
        });
    },
    getMachineList() {
      this.$Progress.start();
      webServices
        .getMachineList(window.localStorage.getItem("userId"))
        .then((res) => {
          this.$Progress.finish();
          this.machineData = res.data;
          console.log(res.data);
        })
        .catch((err) => {
          this.$Progress.fail();
          if (err.response.status === 401) {
            webServices.tokenExpire();
          }
          this.$toast.error({
            title: "ERROR",
            message: {
              error: err.response.data,
              code: err.response.status,
              text: err.response.statusText,
            },
          });
          console.log("error @machine");
          console.log(err);
        });
    },
    pageChanged(PageNum) {
      // window.scrollTo(0, 0)
      this.searchData.page = PageNum;
      this.getMachine();
    },
  },
};
</script>
<style>
</style>
